import React, { Component } from "react"

import Layout from "../components/layout"
import { FooterAlternate1 } from "../components/footer"
import SEO from "../components/seo"

class IndexPage extends Component {
    componentDidMount() {
        setTimeout(function() {
            const scriptBody = document.createElement("script");
            const scriptText = document.createTextNode(`
                var _jaJobsSettings = {
                    key: "AU2_4jad7mxp6avetfodis4tvgmceq",
                    jobListSettings: {
                        jobsPerPage: 10,
                    },
                    applicationFormSettings: {
                        useExternalApplicationForm: true,
                        showExternalApplicationFormInNewWindow: false
                    }
                };
            `);
            scriptBody.appendChild(scriptText);
            document.body.appendChild(scriptBody);
    
            var script = document.createElement("script");
            script.src = "//apps.jobadder.com/widgets/v1/jobs.min.js";
            document.body.appendChild(script);
        }, 500)
    }

    render() {
        return (
            <Layout>
                <SEO title="Featured Jobs" />

                <header className="header header--basic ">
                    <div className="header__content">
                        <div className="container">
                            <div className="row">
                                <div className="header__inner col-md-9 col-lg-7 col-lg-offset-1">
                                    <h1 className="header__title">Featured Jobs</h1>
                                    <h3 className="header__caption">Current job opportunities from SalesTribe clients and partners.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container salesjobs-content">
                    <div id="ja-jobs-widget"></div>
                </div>

                <br /><br />

                <FooterAlternate1 />
            </Layout>
        )
    }
}

export default IndexPage 
